import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"container content"},[_vm._m(0),_c('div',{staticClass:"search-options"},[_c('div',{staticClass:"box-container"},[_c('div',{staticClass:"box-half grant-search"},[_c(VCardTitle,[_c(VTextField,{staticClass:"ml-n6",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"box-quarter"},[_c(VSelect,{staticClass:"pt-4 pl-16",attrs:{"items":_vm.types,"label":"Type","outlined":"","hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',{staticClass:"box-quarter"},[_c(VSelect,{staticClass:"pt-4 pl-16",attrs:{"items":_vm.centers,"label":"Center","outlined":"","hide-details":""},model:{value:(_vm.center),callback:function ($$v) {_vm.center=$$v},expression:"center"}}),_c(VSwitch,{staticClass:"pl-16 m-0",attrs:{"label":"Include completed grants","inset":""},on:{"change":function($event){return _vm.getAwards()}},model:{value:(_vm.includeCompletedGrants),callback:function ($$v) {_vm.includeCompletedGrants=$$v},expression:"includeCompletedGrants"}})],1)])]),_c('div',{staticClass:"results-count mt-n8"},[_vm._v(" Showing "),_c('strong',[_vm._v(_vm._s(_vm.count))]),_vm._v(" Grant"),(_vm.count != 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.awards,"search":_vm.search,"multi-sort":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"pagination":_vm.getPageCount},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.id}},[_vm._v(_vm._s(item.name))])]}},{key:"item.center",fn:function(ref){
var item = ref.item;
return [(item.center == 'GovEx')?_c(VChip,{staticClass:"white--text font-weight-medium govex-chip",attrs:{"color":"var(--govex)"}},[_vm._v(_vm._s(item.center))]):(item.center == 'BCPI')?_c(VChip,{staticClass:"white--text font-weight-medium bcpi-chip",attrs:{"color":"var(--bcpi)"}},[_vm._v(_vm._s(item.center))]):(item.center == 'Partner')?_c(VChip,{staticClass:"white--text font-weight-medium"},[_vm._v(_vm._s(item.center))]):_c('span',[_vm._v("-")])]}},{key:"item.type[1]",fn:function(ref){
var item = ref.item;
return [(item.type)?_c('span',[_vm._v(_vm._s(item.type[1]))]):_c('span',[_vm._v("-")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatNull")(_vm._f("formatDate")(item.start_date))))])]}},{key:"item.planned_end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatNull")(_vm._f("formatDate")(item.planned_end_date))))])]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-type"},[_c('div',{staticClass:"award-icon-box"},[_c('div',{staticClass:"award-icon"})]),_c('h2',[_vm._v("Grants")])])}]

export { render, staticRenderFns }